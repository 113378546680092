export const Icons = ({ ticket }: any) => {
  return (
    <dl className="flex items-center gap-2 flex-wrap">
      <p
        className={`rounded-full px-2 text-sm text-white ${
          ticket.status === "In Progress" ? "bg-green-500" : ""
        } ${ticket.status === "Reviewing" ? "bg-orange-400" : ""}${
          ticket.status === "In Development (Testing)" ? "bg-teal-500" : ""
        }${ticket.status === "Closed" ? "bg-gray-400" : ""}${
          ticket.status === "In Development" ? "bg-blue-600" : ""
        }`}
      >
        {ticket.status}
      </p>
      {
        ticket.type > '' ?(
      <p className="bg-white border-gray-300  border-[1px] rounded-full px-2 text-black text-sm">
        {ticket.type}
      </p>
        ): null
      }
      <p className="border-[1px] bg-white border-gray-300 rounded-full px-2 text-black text-sm">
        #{ticket.id}
      </p>
    </dl>
  );
};
