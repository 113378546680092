import { UserInterface } from "../types/types";
import { FC } from "react";

interface UserComponentInterface {
  userInfo: UserInterface | undefined;
}

export const TopBar: FC<UserComponentInterface> = ({ userInfo }) => {
  return (
    <div className="p-3 flex flex-col md:flex-row h-[90px] justify-between items-center bg-white border-b-2">
      <div className="flex gap-4 items-center">
        <img src="/logo-tradepeg.png" alt="logo" className="h-14" />
        <h1 className="font-semibold text-[30px] sm:h-[35px]">
          Support Portal
        </h1>
      </div>
      {userInfo && (
        <div className="hidden sm:flex gap-2 items-center">
          <h2 className="font-medium text-[20px]">{userInfo.name}</h2>{" "}
          <img src={userInfo.organization.logo} alt="logo" className="h-10" />
        </div>
      )}
    </div>
  );
};
