import { FilterInterface } from "../types/types";
import { FC } from "react";
import { AiOutlineMenuFold } from "react-icons/ai";

export const Filter: FC<FilterInterface> = ({
  handleSearch,
  filtered,
  handleFilter,
  setOpenDrawer,
  openDrawer,
}) => {
  return (
    <div>
      <div className="flex px-3 pt-1.5 h-[60px] gap-2">
        <input
          type="text"
          placeholder="Search Ticket"
          className="py-2 px-2 h-[48px] border-2 border-gray-200 rounded-2xl w-full"
          onChange={handleSearch}
        />
      </div>
      <div className="flex justify-between mb-0.5 border-b border-gray-200">
        <ul
          className="flex -mb-px text-sm font-medium text-center"
          id="myTab"
          data-tabs-toggle="#myTabContent"
          role="tablist"
        >
          <li className="mr-2" role="presentation">
            <button
              className={`inline-block px-4 py-2 rounded-t-lg text-[16px] ${
                filtered === "" ? "border-blue-500 border-b-2" : ""
              }`}
              id="profile-tab"
              data-tabs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              onClick={() => handleFilter("")}
            >
              All
            </button>
          </li>
          <li className="mr-2" role="presentation">
            <button
              className={`inline-block px-4 py-2 rounded-t-lg text-[16px] ${
                filtered === "OPEN" ? "border-blue-500 border-b-2" : ""
              }`}
              id="dashboard-tab"
              data-tabs-target="#dashboard"
              type="button"
              role="tab"
              aria-controls="dashboard"
              aria-selected="false"
              onClick={() => handleFilter("OPEN")}
            >
              Open
            </button>
          </li>
          <li className="mr-2" role="presentation">
            <button
              className={`inline-block px-4 py-2 rounded-t-lg text-[16px] ${
                filtered === "CLOSED" ? "border-blue-500 border-b-2" : ""
              }`}
              id="dashboard-tab"
              data-tabs-target="#dashboard"
              type="button"
              role="tab"
              aria-controls="dashboard"
              aria-selected="false"
              onClick={() => handleFilter("CLOSED")}
            >
              Closed
            </button>
          </li>
        </ul>
        <button
          className="lg:hidden text-[25px] mr-4"
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <AiOutlineMenuFold />
        </button>
      </div>
    </div>
  );
};
