import { TicketsInterface } from "../types/types";
import { FC } from "react";
import { useEffect, useState, useRef } from "react";
import { Icons } from "./Icons";

interface TicketComponentInterface {
  ticket: TicketsInterface | undefined;
  handleTicketOpen: (value: number, value2: string) => void;
  selectedTicket: number | undefined;
}

export const Ticket: FC<TicketComponentInterface> = ({
  ticket,
  handleTicketOpen,
  selectedTicket,
}) => {
  
  const descriptionRef = useRef<HTMLSpanElement | null>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  // check if the text message is longer then two lines
  // if it is truncate and add read more button
  const hasLongText = () => {
    if (descriptionRef.current) {
      const lineHeight = parseFloat(
        getComputedStyle(descriptionRef.current).lineHeight
      );
      const maxHeight = lineHeight * 2;
      return descriptionRef.current.scrollHeight > maxHeight;
    }
    return false;
  };
  const [longText, setLongText] = useState(false);

  useEffect(() => {
    setLongText(hasLongText());
  }, []);

  function getTimeSince(inputDate: string): string {
    const currentDate = new Date();
    const inputDateTime = new Date(inputDate);
    const timeDifference = currentDate.getTime() - inputDateTime.getTime();

    if (timeDifference < 60000) {
      // Less than 1 minute
      const seconds = Math.floor(timeDifference / 1000);
      return `${seconds}s`;
    } else if (timeDifference < 3600000) {
      // Less than 1 hour
      const minutes = Math.floor(timeDifference / 60000);
      return `${minutes}m`;
    } else if (timeDifference < 86400000) {
      // Less than 1 day
      const hours = Math.floor(timeDifference / 3600000);
      return `${hours}h`;
    } else if (timeDifference < 604800000) {
      // Less than 7 days
      const days = Math.floor(timeDifference / 86400000);
      if (days > 1) {
        return `${days} days`;
      }
      return `yesterday`;
    } else {
      // 7 days or more
      const month = inputDateTime.toLocaleString("default", { month: "short" });
      const day = inputDateTime.getDate();
      return `${month} ${day}`;
    }
  }

  return (
    <>
      {ticket && (
        <div
          key={ticket.id}
          className={`flex flex-row ${
            expanded ? "" : "xxs:h-[175px] sm:h-[150px]"
          }  py-4 px-3 justify-center items-center my-[5px] mx-[10px] rounded-xl border-y-[0.1px] shadow-md border-gray-200 border-l-[7px] ${
            ticket.open ? " border-l-green-300" : "border-l-gray-400 "
          } ${selectedTicket === ticket.id ? "bg-sky-100" : "bg-white"}
        `}
          onClick={() => handleTicketOpen(ticket.id, ticket.token)}
        >
          <div className="w-full">
            <div className="text-lg flex justify-between items-center mb-2">
              <h2 className="font-semibold truncate xxs:max-w-[200px] xs:max-w-[240px] sm:max-w-[330px]">
                {ticket.subject}
              </h2>
              <dd className="text-xs text-slate-800 font-bold">
                {getTimeSince(ticket.created)}
              </dd>
            </div>
            <div className="text-sm text-slate-500 relative mb-6">
              <span
                ref={descriptionRef}
                id={`descriptionText`}
                className={`text-sm font-medium h-[40px] text-slate-600 mr-12 ${
                  expanded ? "" : "line-clamp-2"
                }`}
              >
                {ticket.description}
              </span>
              {longText && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpanded(!expanded);
                  }}
                  className={`text-blue-500 cursor-pointer mr-12 ${
                    expanded ? "block" : "absolute"
                  } bottom-[0px] right-0 ${
                    selectedTicket === ticket.id ? "bg-sky-100" : "bg-white"
                  }`}
                >
                  {expanded ? "Read less" : "... Read more"}
                </button>
              )}
            </div>
            <Icons ticket={ticket} />
          </div>
        </div>
      )}
    </>
  );
};
