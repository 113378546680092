import axios from "axios";
import { useEffect, useState } from "react";
import { TicketsInterface, UserInterface } from "../types/types";
import { Chat } from "./Chat";
import { Ticket } from "./Ticket";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Filter } from "./Filter";
import { TopBar } from "./TopBar";

export const Homepage = () => {
  const [tickets, setTickets] = useState<TicketsInterface[] | undefined>(
    undefined
  );

  const MySwal = withReactContent(Swal);
  const [selectedTicket, setSelectedTicket] = useState<number | undefined>();
  const [topIndex, setTopIndex] = useState<number>(0);
  const [openDrawer, setOpenDrawer] = useState<boolean>(true);

  const [id, setId] = useState<number | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [userToken, setUserToken] = useState<string | null>(null);
  const [hideBar, setHideBar] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [filtered, setFiltered] = useState("OPEN");

  const [userInfo, setUserInfo] = useState<UserInterface | undefined>();

  const [filteredTickets, setFilteredTickets] = useState<TicketsInterface[]>(
    []
  );

  const [search, setSearch] = useState<string>("");

  const getUser = async (userToken: string | null) => {
    try {
      const response = await axios.get(
        `https://api-prod.tradepeg.com/user/self`,
        {
          headers: {
            Authorization: userToken,
          },
        }
      );
      setUserInfo(response.data);

      if (response.data.emailValidated) {
        getTicket(userToken, "OPEN");
      } else {
        setLoading(false);
        setTickets([]);
        MySwal.fire({
          title: "Your Email is not validated",
          icon: "warning",
          allowOutsideClick: false,
          confirmButtonText: "Request email validation",
        }).then((result) => {
          if (result.isConfirmed) {
            validateEmail(userToken);
          }
        });
        return;
      }
    } catch (error: any) {
      setLoading(false);
      setTickets([]);
      const responseError = error.response.data.error;
      MySwal.fire({
        title: "Something went wrong",
        text: responseError,
        icon: "error",
        allowOutsideClick: false,
        confirmButtonText: "Refresh",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }
  };

  const getTicket = async (userToken: string | null, filtered: string) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api-prod.tradepeg.com/user/support-ticket/list?status=${filtered}`,
        {
          headers: {
            Authorization: userToken,
          },
        }
      );

      setTickets(
        response.data.data.tickets.sort(
          (a: TicketsInterface, b: TicketsInterface) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        )
      );
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setTickets([]);
      const responseError = error.response.data.error;

      MySwal.fire({
        title: "Something went wrong",
        text: responseError,
        icon: "error",
        allowOutsideClick: false,
        confirmButtonText: "Refresh",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }
  };

  const handleSearch = (e: any) => {
    if (!tickets) return;
    const searchValue = e.target.value.toLowerCase();

    if (searchValue === "") {
      setFilteredTickets([]);
      setSearch("");
    } else {
      setSearch(searchValue);

      setLoading(true);

      const newFilteredTickets = tickets.filter((ticket) => {
        const idString = ticket.id.toString().toLowerCase();
        const typeLower = ticket.type.toLowerCase();
        const subjectLower = ticket.subject.toLowerCase();

        return (
          idString.includes(searchValue) ||
          typeLower.includes(searchValue) ||
          subjectLower.includes(searchValue)
        );
      });
      setLoading(false);

      setFilteredTickets(newFilteredTickets);
    }
  };

  const handleTicketOpen = (newId: number, ticketToken: string) => {
    setTopIndex(0);
    setId(newId);
    setToken(ticketToken);
    setSelectedTicket(newId);
    setOpenDrawer(false);
  };

  const validateEmail = async (userToken: string | null) => {
    try {
      const response = await axios.post(
        `https://api-prod.tradepeg.com/user/email-validation-request`,
        {},
        {
          headers: {
            Authorization: userToken,
          },
        }
      );
      if (!response.data.error) {
        MySwal.fire({
          title: "Email validation sent",
          text: "Upon receipt of the email, you'll find instructions on how to verify your email address. After completing the validation, you'll gain access to view your tickets",
          icon: "info",
          allowOutsideClick: false,
          confirmButtonText: "Close",
        }).then((result) => {
          if (result.isConfirmed) {
            MySwal.close();
          }
        });
      } else {
        const responseError = response.data.error;
        MySwal.fire({
          title: "Something went wrong",
          text: responseError,
          icon: "error",
          allowOutsideClick: false,
          confirmButtonText: "Refresh",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    } catch (error: any) {
      const responseError = error.response.data.error;
      MySwal.fire({
        title: "Something went wrong",
        text: responseError,
        icon: "error",
        allowOutsideClick: false,
        confirmButtonText: "Refresh",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }

    return;
  };

  const handleFilter = (filter: string) => {
    getTicket(userToken, filter);
    setFiltered(filter);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenParam = queryParams.get("token");
    const hideBarParam = queryParams.get("hideBar") === "true";

    setUserToken(tokenParam);
    setHideBar(hideBarParam);

    getUser(tokenParam);
  }, []);

  return (
    <div className="w-full p-1 shadow-lg rounded-lg min-h-[100vh]">
      {!hideBar && <TopBar userInfo={userInfo} />}
      <div
        className={`flex flex-row ${hideBar ? "h-[99vh]" : "h-[calc(99.16vh-90px)]"
          }  justify-between bg-[#f7f7f7]`}
      >
        <div
          className={`flex flex-col flex-shrink-0 flex-grow-0 ${openDrawer ? "w-full" : "w-0"
            } lg:!w-[450px] border-r-2 `}
        >
          <div
            className={`flex flex-col transition-all duration-500 ${openDrawer ? "w-full" : "xxs:hidden lg:flex lg:w-full"
              } `}
          >
            <Filter
              handleSearch={handleSearch}
              filtered={filtered}
              handleFilter={handleFilter}
              setOpenDrawer={setOpenDrawer}
              openDrawer={openDrawer}
            />
            <div
              className={`overflow-y-auto ${hideBar ? "h-[calc(100vh-104px)]" : "h-[calc(100vh-201.8px)]"
                } bg-[#f7f7f7] py-2`}
            >
              {tickets && !loading ? (
                <div>
                  {(search !== "" && filteredTickets.length === 0) ||
                    tickets.length === 0 ? (
                    <div className="flex justify-center items-center mt-20 text-[30px] text-slate-500">
                      <p>No tickets available</p>
                    </div>
                  ) : (
                    (search !== "" ? filteredTickets : tickets).map(
                      (ticket: TicketsInterface, i: number) => (
                        <Ticket
                          key={i}
                          ticket={ticket}
                          handleTicketOpen={handleTicketOpen}
                          selectedTicket={selectedTicket}
                        />
                      )
                    )
                  )}
                </div>
              ) : (
                <div className="flex justify-center items-center mt-20">
                  <ReactLoading type={"bars"} color="#03c160" width={100} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`transition-all duration-500 bg-[#f7f7f7] ${openDrawer
            ? "xxs:w-0 xxs:overflow-hidden lg:block lg:w-full"
            : "w-full"
            } h-full`}
        >
          {(search !== "" && filteredTickets.length === 0) ||
            (tickets && tickets.length === 0) ? (
            ""
          ) : (
            <Chat
              id={id}
              token={token}
              hideBar={hideBar}
              userToken={userToken}
              topIndex={topIndex}
              setTopIndex={setTopIndex}
              setOpenDrawer={setOpenDrawer}
              openDrawer={openDrawer}
              logo={userInfo && userInfo.organization.logo}
            />
          )}
        </div>
      </div>
    </div>
  );
};
