import { TicketInterface } from "../types/types";
import { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import axios from "axios";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Icons } from "./Icons";

interface ChatProps {
  id: number | null;
  token: string | null;
  hideBar: boolean;
  userToken: string | null;
  topIndex: number;
  setTopIndex: (value: number) => void;
  setOpenDrawer: (value: boolean) => void;
  openDrawer: boolean;
  logo: string | undefined;
}

export const Chat: FC<ChatProps> = ({
  id,
  token,
  userToken,
  hideBar,
  topIndex,
  setTopIndex,
  setOpenDrawer,
  openDrawer,
  logo,
}) => {
  const [chat, setChat] = useState<TicketInterface | null>(null);
  const MySwal = withReactContent(Swal);

  const [loading, setLoading] = useState(false);

  let currentDate: any = null;

  // format the date in the chat
  function getTimeSince(inputDate: string): string {
    const currentDate = new Date();
    const inputDateTime = new Date(inputDate);

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const inputYear = inputDateTime.getFullYear();
    const inputMonth = inputDateTime.getMonth();
    const inputDay = inputDateTime.getDate();

    if (
      currentYear === inputYear &&
      currentMonth === inputMonth &&
      currentDay === inputDay
    ) {
      return "today";
    } else if (
      currentYear === inputYear &&
      currentMonth === inputMonth &&
      currentDay === inputDay + 1
    ) {
      return "yesterday";
    } else {
      const timeDifference = currentDate.getTime() - inputDateTime.getTime();
      const oneDayInMillis = 24 * 60 * 60 * 1000;

      const daysAgo = Math.floor(timeDifference / oneDayInMillis);

      if (daysAgo <= 7) {
        return `${daysAgo} days ago`;
      } else {
        const month = inputDateTime.toLocaleString("default", {
          month: "short",
        });
        const day = inputDay;
        return `${month} ${day}`;
      }
    }
  }

  const parentRef = useRef<HTMLDivElement | null>(null);

  const getTicket = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `https://api-prod.tradepeg.com/user/support-ticket/${id}?token=${token}`,
        {
          headers: {
            Authorization: userToken,
          },
        }
      );

      setChat(response.data.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setChat(null);
      const responseError: string = error.response.data;

      MySwal.fire({
        title: "Something went wrong",
        icon: "error",
        text: responseError,
        allowOutsideClick: false,
        confirmButtonText: "Refresh",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }
  };

  // used to get the right date to set on the top of the chat
  const handleScroll = () => {
    if (parentRef.current) {
      const parentRect = parentRef.current.getBoundingClientRect();
      const divElements = parentRef.current.querySelectorAll("#chat-message");
      if (divElements.length === 0) {
        setTopIndex(0);
        return;
      }

      divElements.forEach((divElement, index) => {
        const divRect = divElement.getBoundingClientRect();
        if (
          parentRef.current!.scrollTop + 10 <=
            parentRef.current!.scrollHeight - parentRef.current!.offsetHeight &&
          index === undefined
        ) {
          return;
        }

        if (divRect.top <= parentRect.top + 70) {
          setTopIndex(index);
        }
      });
    }
  };

  // scroll chat to the bottom on first render
  const scrollToBottom = () => {
    const chatContainer = parentRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight + 500;
    }
  };

  useEffect(() => {
    if (id) {
      getTicket();
    }
  }, [id]);

  useEffect(() => {
    parentRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      parentRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Scroll to the bottom on first render
    scrollToBottom();
  }, [chat]);

  return (
    <div className={`relative flex-1 justify-between`}>
      <div className="xxs:max-h-[150px] h-[98px] border-b-[1px] flex gap-3 items-center justify-between px-3">
        <div className="flex items-center gap-2">
          <button
            className="lg:hidden text-[25px] ml-2"
            onClick={() => setOpenDrawer(!openDrawer)}
          >
            <AiOutlineMenuUnfold />
          </button>
          <div className="pl-2">
            <p className="font-semibold text-[30px] h-[45px] line-clamp-1">
              {chat && chat.subject}
            </p>
            {chat && (
              <div>
                <p>
                  Sender: <span className="font-medium">{chat.sender}</span>
                </p>
                <p>
                  Agent: <span className="font-medium ml-2">{chat.agentName}</span>
                </p>
              </div>
            )}
          </div>
        </div>
        {chat && (
          <div className="gap-2 flex-col my-2 items-center hidden lg:flex">
            <Icons ticket={chat} />
          </div>
        )}
      </div>
      <div
        ref={parentRef}
        id="messages"
        className={`flex justify-between space-y-4 p-3 overflow-y-auto ${
          hideBar ? "h-[calc(100vh-105px)]" : "h-[calc(100vh-195px)]"
        } scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch w-full`}
      >
        {!loading && chat && chat.conversations.length > 0 && (
          <div className="w-full absolute h-10 bg-transparent top-[95px] -left-0 flex justify-center items-center">
            <p
              className={`z-10 w-[120px] rounded-full px-2 py-0.5 bg-white text-gray-600 border-[1px] border-gray-200 text-center ${
                openDrawer ? "xxs:hidden lg:block" : ""
              } `}
            >
              {getTimeSince(
                chat?.conversations
                  .slice()
                  .reverse()
                  [topIndex].created_at.slice(0, 10)
              )}
            </p>
          </div>
        )}
        {!loading ? (
          chat ? (
            chat.conversations.length > 0 ? (
              <div className="w-full flex flex-col gap-1">
                {chat.conversations
                  .slice()
                  .reverse()
                  .map((conversation: any, i: number) => {
                    const messageDate = conversation.created_at.slice(0, 10);
                    let dateSeparator = null;
                    if (messageDate !== currentDate) {
                      dateSeparator = (
                        <div className="relative top-0 text-center z-10 mb-2 px-2 py-0.5 m-auto w-full rounded-full text-gray-300 flex justify-center">
                          <span className="text-gray-500 truncate w-fit block relative z-20 bg-[#f7f7f7] px-1">
                            {getTimeSince(messageDate.slice(0, 10))}
                          </span>
                          <div className="absolute left-0 -top-[12px] h-full border-b-[1px] border-gray-300 w-full"></div>
                        </div>
                      );
                      currentDate = messageDate;
                    }
                    return (
                      <div key={i} id="chat-message">
                        <div></div>
                        {i !== topIndex && dateSeparator}
                        <div
                          className={`flex items-start gap-1 ${
                            i + 1 === chat.conversations.length
                              ? "pb-8"
                              : "pb-1"
                          } ${!conversation.incoming ? "" : "justify-end"}`}
                        >
                          <div className="flex flex-col space-y-0.5 text-xs max-w-xs order-2 xxs:max-w-[50vw] sm:max-w-[50vw] lg:max-w-[35vw]">
                            <span
                              className={`px-4 py-2 rounded-xl inline-block text-lg mt-4 break-words ${
                                !conversation.incoming
                                  ? "text-gray-600 rounded-tl-none bg-gray-200"
                                  : "text-white rounded-tr-none bg-blue-600"
                              } `}
                            >
                              {conversation.body
                                .replace(/\n/g, "abcdefgabcdefg")
                                .replace(/ {3,}/g, "abcdefgabcdefg")
                                .split("abcdefgabcdefg")
                                .filter((line: any)=> line > '' && (!line.startsWith('[cid:') || !!line.endsWith('[')))
                                .map((line: any, index: any) => (
                                  <p key={index} className="mb-0">
                                    {line}
                                  </p>
                                ))}
                            </span>
                            <p
                              className={`${
                                !conversation.incoming
                                  ? "self-end"
                                  : "self-start"
                              }`}
                            >
                              {conversation.created_at.slice(11, 16)}
                            </p>
                          </div>
                          <div
                            className={`flex relative ${
                              !conversation.incoming ? "order-1" : "order-2"
                            }`}
                          >
                            {!conversation.incoming ? (
                              <div className="h-8 w-8 border-gray-300 border-[1px] rounded-full flex items-center justify-center">
                                <img
                                  src={"/logo-tradepeg.png"}
                                  alt="logo"
                                  className="h-[22px] w-[22px] mb-1 object-cover"
                                />
                              </div>
                            ) : (
                              <img
                                src={logo}
                                alt="logo"
                                className="h-8 w-8 object-cover border-gray-300 border-[1px] rounded-full"
                              />
                            )}
                            <p
                              className={`absolute font-medium flex items-center justify-center rounded-full h-14 w-14 -mt-4 -top-2 ${
                                !conversation.incoming
                                  ? "order-1 -right-[61px]"
                                  : "order-2 -left-14"
                              }`}
                            >
                              {!conversation.incoming
                                ? conversation.from
                                    .split("<")[0]
                                    .split(" ")[0]
                                    .replaceAll('"', "")
                                : "You"}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="h-[70vh] flex justify-center items-center w-full text-[35px] text-slate-500">
                <p>No conversation yet</p>
              </div>
            )
          ) : (
            <div className="h-[70vh] flex justify-center items-center w-full text-[35px] text-slate-500">
              <p>Select a ticket</p>
            </div>
          )
        ) : (
          <div className="flex justify-center pt-[150px] w-full">
            <ReactLoading type={"bars"} color="#03c160" width={150} />
          </div>
        )}
      </div>
    </div>
  );
};
